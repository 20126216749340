import React from 'react';
import {
  createdAtColumn,
  generateColumn,
  IDCOLUMN,
  product_type_column,
  sorterText,
} from './ColumnUtils';
import { formatPrice } from '../utils/formatPrice';
import { Tag, Tooltip, Popover } from 'antd';
import dayjs from 'dayjs';
import { getTimeZone } from '../utils/getLocation';
import { compareByCreatedAt, compareByDate } from '../utils/compareByDate';
import { useTranslation } from 'react-i18next';
import { BiCheckboxChecked, BiErrorAlt, BiXCircle } from 'react-icons/bi';

const commonColumns = [IDCOLUMN];

export const USESalesColumns = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  const useproduct_type_column = product_type_column();
  return [
    ...commonColumns,
    generateColumn(
      t('member', 'Socio'),
      'customer_name',
      '7%',
      false,
      sorterText('customer_name')
    ),
    generateColumn(
      t('seller', 'Vendedor'),
      'seller_name',
      '7%',
      false,
      sorterText('seller_name')
    ),
    useproduct_type_column,

    generateColumn(
      t('product', 'Producto'),
      'name',
      '11%',
      (text, record) => <span>{record.product?.name}</span>,
      sorterText('name')
    ),
 
  
    // generateColumn(
    //   t('discount', 'Descuento'),
    //   'discount_amount',
    //   '7%',
    //   (text) => <span>{formatPrice(text)}</span>,
    //   sorterText('discount_amount')
    // ),
    // generateColumn(t('quantity_singluar','Cantidad'), 'quantity','5%',false,sorterText('quantity')),
    generateColumn(
      t('sales.payment_method', 'Forma de pago'),
      'payments',
      '8%',
      (tags) => (
        <>
          {tags.map((tag, i) => {
            let color = tag.length > 3 ? 'geekblue' : 'green';
            if (tag.name == 'Sales') {
              color = 'volcano';
            } else if (tag.name == 'Owner') {
              color = 'geekblue';
            }
            return (
              <Tooltip
                title={`${formatPrice(tag.amount)} en ${
                  tag.payment_method_name
                }`}
                key={i}
              >
                <Tag
                  color={color}
                  bordered={false}
                  key={i}
                  className={` py-1 text-xs ${tags.length > 0 && 'mb-1'} text-ellipsis overflow-hidden whitespace-nowrap  w-[100%]  text-center`}
                >
                  {tag.payment_method_name.toUpperCase()}
                </Tag>
              </Tooltip>
            );
          })}
        </>
      ),
      {
        sorter: (a, b) =>
          a.payments[0]?.payment_method_id > b.payments[0]?.payment_method_id
            ? 1
            : -1,
      }
    ),
    generateColumn(
      t('sales.card_attempt', 'Intentos de cobro'),
      'card_attempts',
      '8%',
      (attempts) => (
        <div className='flex flex-row gap-2'>
          {attempts.
          sort((a,b)=>compareByDate(b,a,'attempted_at')).
          slice(0,4).
          map((card_attempt, i) => (
            <Popover
              className='cursor-pointer'
              placement='bottom'
              content={
                <div className='flex flex-col  justify-between gap-1'>
                  <div className='flex flex-row justify-between'>
                    <p>Estado:</p>
                    <Tag
                      color={
                        card_attempt.status == 'approved'
                          ? 'green'
                          : card_attempt.status == 'in_process'
                          ? 'yellow'
                          : 'red'
                      }
                    >
                      {card_attempt.status}
                    </Tag>
                  </div>
                  <div className='flex flex-row justify-between '>
                    <p className='w-[50%]'>
                      {t('sales.operation_number', 'Número de operación')}:
                    </p>
                    <p>{card_attempt.operation_reference}</p>
                  </div>
                  <div className='flex flex-row justify-between'>
                    <p>Monto:</p>
                    <p>{formatPrice(card_attempt.amount)}</p>
                  </div>
                  <div className='flex flex-row justify-between'>
                    <p>Descripción:</p>
                    <p className=''>{card_attempt.rejected_description}</p>
                  </div>

                  <div className='flex flex-row justify-between'>
                    <p>Fecha:</p>
                    <p>
                      {dayjs(card_attempt.attempted_at)
                        .tz(getTimeZone())
                        .format('DD/MM/YYYY HH:mm A')}
                    </p>
                  </div>
                  <div className='flex flex-row justify-between'>
                    <p>ID Tarjeta:</p>
                    <p>{card_attempt.credit_card_id}</p>
                  </div>
                </div>
              }
            >
              <div
                key={i}
                className={` w-3 h-3 rounded-full ${
                  card_attempt.status == 'approved'
                    ? ' bg-primaryGreen'
                    : card_attempt.status == 'in_process'
                    ? 'bg-primaryYellow'
                    : 'bg-primaryRed'
                }  `}
              ></div>
            </Popover>
          ))}
          {attempts.length > 4 && (
            <p className='text-white text-xs'>
              +{attempts.length - 4}{' '}
            </p>
          )}

        </div>
      ),
      sorterText('card_attempts'),
      [],
      true
    ),
    generateColumn(
      t('squared', 'Venta saldada'),
      'squared',
      '5%',
      (t) =>
        t ? (
          <BiCheckboxChecked size={29} color='#1EA05A' />
        ) : (
          <BiXCircle size={29} color='#ED4C5C' />
        ),
      {
        filters: [
          {
            text: 'Saldada',
            value: true,
          },
          {
            text: 'No Saldada',
            value: false,
          },
        ],
        onFilter: (value, record) => record.squared == value,
      }
    ),
    generateColumn(
     'Deuda ',
      'due_total',
      '7%',
      (text) => <span>{formatPrice(text)}</span>,
      sorterText('due_total')
    ),
    // generateColumn(
    //   t('subtotal_singlular', 'Sub Total'),
    //   'sub_total',
    //   '7%',
    //   (text) => <span>{formatPrice(text)}</span>,
    //   sorterText('sub_total')
    // ),
    generateColumn(
      t('total_singular', 'Total'),
      'total',
      '7%',
      (text) => <span>{formatPrice(text)}</span>,
      sorterText('total')
    ),
    // generateColumn('Creado', 'created_at','7%'),

    createdAtColumn,
  ];
};

export const USEPaymentsColumns = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  return [
    ...commonColumns,
    generateColumn(t('sale.titleID', 'Venta ID'), '', '7%', (text, record) => (
      <span>{record.sale.id}</span>
    )),
    generateColumn(t('buyier', 'Comprador'), 'customer_name', '10%'),
    generateColumn(t('seller', 'Vendedor'), '', '10%', (text, record) => (
      <span>{record.sale.seller_name}</span>
    )),
    generateColumn(
      t('sales.payment_method', 'Metodo de pago'),
      'payment_method_name',
      '10%'
    ),

    generateColumn(t('currency', 'Moneda'), 'currency', '7%'),
    generateColumn(
      t('quantity_singluar', 'Cantidad'),
      '',
      '6%',
      (text, record) => <span>{record.sale.quantity}</span>
    ),
    generateColumn(
      t('discount', 'Descuento'),
      '',
      '7%',
      (text, record) => <span>{formatPrice(record.sale.discount_amount)}</span>,
      sorterText('discount_amount')
    ),
    generateColumn(
      t('subtotal_singlular', 'Sub Total'),
      '',
      '7%',
      (text, record) => <span>{formatPrice(record.sale.sub_total)}</span>
    ),
    generateColumn(t('total_singular', 'Total'), 'amount', '10%', (text) => (
      <span>{formatPrice(text)}</span>
    )),
    generateColumn(
      t('sales.operation_number', 'Número de operación'),
      'operation_number',
      '10%',
      (text, record) => (
        <span>
          {record.card_attempts?.find((p) => p.payment_id == record.id)
            ?.operation_reference || ''}
        </span>
      ),
      false,
      [],
      true
    ),
    generateColumn(
      t('state_singular', 'Estado'),
      'status',
      '7%',
      (text, record) => (
        <span>
          {record.card_attempts?.find((p) => p.payment_id == record.id)
            ?.status || ''}
        </span>
      ),
      false,
      [],
      true
    ),
    createdAtColumn,
  ];
};
export const USETransactionsColumns = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  return [
    ...commonColumns,
    generateColumn(t('point_of_sale', 'Punto de venta'), 'point_of_sale'),
    generateColumn(t('users.user', 'Usuario'), 'user_name'),
    generateColumn(t('description', 'Descripción'), 'description', '10%'),
    generateColumn(t('category', 'Categoria'), 'transaction_category_name'),
    generateColumn(
      t('sales.payment_method', 'Metodo de pago'),
      'payment_method_name'
    ),
    generateColumn(t('type', 'Tipo'), 'transaction_type', '10%', (text) => (
      <span>{text == 'expense' ? 'Gasto' : 'Ingreso'}</span>
    )),
    generateColumn(
      t('total_singular', 'Total'),
      'amount',
      '10%',
      (text, record) => (
        <span
          className={`
       font-MessinaSansSemiBold
       text-md
      ${
        record.transaction_type == 'expense' ? 'text-red-500' : 'text-green-500'
      }
      `}
        >
          {' '}
          {record.transaction_type == 'expense' && '-'} {formatPrice(text)}
        </span>
      )
    ),
    createdAtColumn,
  ];
};
export const USEStockColumns = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  return [
    ...commonColumns,
    generateColumn(t('product', 'Producto'), 'product_name'),
    generateColumn(t('location', 'Sede'), 'location_name'),
    generateColumn(t('units', 'Unidades'), 'units'),

    createdAtColumn,
  ];
};
export const USEBalanceColumns = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  return [
    generateColumn(
      t('point_of_sale', 'Punto de venta'),
      '',
      '8%',
      (text, record) => <span>{record.point_of_sale.name}</span>
    ),
    generateColumn(
      t('users.user_open', 'Usuario Apertura'),
      '',
      '10%',
      (text, record) => (
        <span>{record.user.first_name + ' ' + record.user.last_name}</span>
      )
    ),
    generateColumn(
      t('balance.date_opened', 'Fecha Apertura'),
      '',
      '10%',
      (text, record) => (
        <span>
          {dayjs(
            record.balance_opened_date
              ? record.balance_opened_date
              : record.created_at
          )
            .tz(getTimeZone())
            .format('DD/MM/YYYY HH:mm A')}
        </span>
      ),
      {
        sorter: (a, b) => compareByCreatedAt(a, b),
        defaultSortOrder: 'ascend',
      }
    ),
    generateColumn(
      t('balance.total_opened', 'Total Apertura'),
      'open_amount',
      '10%',
      (text) => <span>{text && formatPrice(text)}</span>
    ),
    generateColumn(
      t('balance.date_closed', 'Fecha Cierre'),
      '',
      '10%',
      (text, record) => (
        <span>
          {record.balance_close_created_at
            ? dayjs(record.balance_close_created_at)
                .tz(getTimeZone())
                .format('DD/MM/YYYY HH:mm A')
            : ''}
        </span>
      ),
      {
        sorter: (a, b) => compareByCreatedAt(a, b),
      }
    ),
    generateColumn(
      t('balance.close_amount', 'Total Cierre'),
      'balance_close_amount',
      '10%',
      (text) => <span>{text ? formatPrice(text) : ''}</span>
    ),
    generateColumn(
      t('users.user_close', 'Usuario Cierre'),
      '',
      '10%',
      (text, record) => (
        <span>
          {record.close_user
            ? record?.close_user?.first_name +
              ' ' +
              record?.close_user?.last_name
            : ''}
        </span>
      )
    ),

    generateColumn(
      t('difference', 'Diferencia'),
      'balance_close_difference',
      '10%',
      (text) => (
        <span
          className={`${
            text && parseInt(text) < 0 ? 'text-primaryRed' : 'text-white'
          }`}
        >
          {text ? formatPrice(text) : ''}
        </span>
      )
    ),
  ];
};
