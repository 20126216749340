import React, { useRef, useState, useEffect } from 'react';
import { Select, Skeleton, notification ,message} from 'antd';
import SelectPaymentMethod from '../SelectPaymentMethod';
import { getCurrency, getCurrencyLocale, getLocationId } from '../../services/utils/getLocation';
import { formatError } from '../../services/utils/formatError';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '../../context/AuthProvider';
import { formatPrice } from '../../services/utils/formatPrice';

import FooterSaleModal from '../helpers/FooterSaleModal';
import { createPayment } from '../../api/payments';
import { createCreditNotes, getSale } from '../../api/sales';
import { useGetDischargeReasons } from '../../api/prospects';
import { updateSubscription } from '../../api/subscriptions';
import { t } from 'i18next';
import { pay_by_link_tokens, useGetPaymentPlatforms } from '../../api/mp';
import MPCardHolder from '../Desktop/MPCardHolder';
import { CiLink } from 'react-icons/ci';
import { closeMessageApi, loadingLink } from './MessageApi';
import { openLinkNotification } from '../../services/utils/openNotificationFields';

export default function ModalCC({
  sale,
  member_id,
  setClose,
  openNotification,
  dischargeReasons,
  owner_user_id,
  owner_email,
  owner_type,
  document_types,
  member_name,
  api
}) {
  if (sale == false) return null;
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const dischargeRef = useRef(null);
  const [messageApi, contextHolderMessage] = message.useMessage();

  const { data: dischargeReasonsList, isLoading: isLoadingDischarge } =
    dischargeReasons?.length > 0
      ? { data: dischargeReasons, isLoading: false }
      : useGetDischargeReasons();
  const dischargeReasonsFilter = dischargeReasonsList?.filter(
    (reason) => reason.discharge_reason_type_id == 8
  );
  const [selectedProduct, setselectedProduct] = useState(null);
  const [paymentType, setPaymentType] = useState(null);
  const [openAddCreditCard, setopenAddCreditCard] = useState(false);

  const [selectedReason, setselectedReason] = useState(null);
  const {data:paymentPlatform,isLoading:loadingPlatforms}= useGetPaymentPlatforms(getLocationId());

  useEffect(() => {
    if (sale.product_id) {
      fetchProduct();
    }
  }, [sale]);
  const fetchProduct = async () => {
    const saleData = await getSale(sale.id);
    // const discount = discounts.find((d) => d.id == data_product.discount_id);
    //checkear si tuvo un descuento q aparezca
    setselectedProduct({
      product: sale.product,
      price: sale.total_due,
      countProducts: sale.quantity,
      subscription_id: saleData.subscription_id,
      selectedPaymentMethods: [{ paymentType: null, amount: sale.total_due }],
    }); //discount:discount
  };
  const needLinkMp = ()=>{
    // return false
    return paymentPlatform &&selectedProduct.selectedPaymentMethods?.some((l)=>l?.paymentType == 52 && !l?.credit_card_id)
  }
  const handleCreateSale = async () => {
    if (!selectedReason && sale.cancelProducts)
      return openNotification('warning', t('select_reason_refund','Seleccioná un motivo de devolución'));
      if(selectedProduct.selectedPaymentMethods.filter((l)=>l?.paymentType).length == 0 && !sale.cancelProducts){
        return openNotification('warning', t('select_payment_methods','Seleccioná  un método de pago'));
      }
      if((needLinkMp()) && !sale.cancelProducts){
        handleLink(selectedProduct.product.id,calculateTotalAmount(),sale.id,sale.point_of_sale_id,sale.customer_type,sale.customer_id);
        return
      }
    if (sale.cancelProducts) {
      const subscription_id = selectedProduct.subscription_id;
      let credit_note = {
        data: {
          sale_id: sale.id,
          location_id: sale.location_id,
          customer_id: sale.customer_id,
          customer_type: sale.customer_type,
          point_of_sale_id: sale.point_of_sale_id,
          user_id: user.user_id,
          discharge_reason_id: selectedReason,
          amount: calculateTotalAmount(),
          currency: sale.currency,
        },
      };
      if (subscription_id) {
        let updatedMembership = {
          id: subscription_id,
          data: {
            owner_id: sale.customer_id,
            owner_type: sale.customer_type,
            days_left: 0,
            sessions_left: 0,
          },
        };
        await useUpdateMembership.mutateAsync(updatedMembership);
      }
      return await useCreateCreditNote.mutateAsync(credit_note);
    }
    selectedProduct.selectedPaymentMethods.forEach(async (paymentMethod) => {
      let payment = {
        data: {
          payment:{
          payment_method_id: paymentMethod.paymentType,
          amount: parseFloat(paymentMethod.amount).toFixed(2),
          currency: sale.currency,
          sale_id: sale.id,
          location_id: sale.location_id,
          customer_id: sale.customer_id,
          customer_type: sale.customer_type,
          point_of_sale_id: sale.point_of_sale_id,
          user_id: user.user_id,
          credit_card_id: paymentMethod.credit_card_id,
          device_id:window.MP_DEVICE_SESSION_ID
          }
        },
      };
      await useCreatePayment.mutateAsync(payment);
    });
  };
  const useCreatePayment = useMutation({
    mutationFn: (sale) => createPayment(sale.data),
    onSuccess: (data) => {
      console.log('data',data);
      // let failedAttempt = data.card_attempts?.some((l)=>l.status !== 'approved');

      openNotification(
        'success',
        t('payment_success','Pago realizado correctamente'),
        t('payment_success_desc','El pago se realizó correctamente')
      
      );
      queryClient.invalidateQueries({
        queryKey: ['sales', sale.customer_id, sale.customer_type],
      });
      queryClient.invalidateQueries({
        queryKey: ['member_cc', sale.customer_id, sale.customer_type],
      });
      setClose();
    },
    onError: (error) => formatError(error, openNotification),
  });
  const useCreateCreditNote = useMutation({
    mutationFn: (refund) => createCreditNotes(refund.data),
    onSuccess: () => {
      openNotification(
        'success',
        t('credit_note_success','La nota de crédito se generó correctamente'),
        
   
      );
      queryClient.invalidateQueries({
        queryKey: ['member_cc', sale.customer_id, sale.customer_type],
      });
      setClose();
      // queryClient.invalidateQueries({
      //   queryKey: ['sales', sale.customer_id,sale.customer_type],
      // });
    },
    onError: (error) => formatError(error, openNotification),
  });
  const useUpdateMembership = useMutation({
    mutationFn: (membership) =>
      updateSubscription(membership.id, membership.data),
    onSuccess: (data) => {
      openNotification('success');
      queryClient.invalidateQueries({
        queryKey: ['profile', sale.customer_id],
      });
    },
    onError: (error) => {
      console.log('error', error);
      formatError(error, openNotification);
    },
  });
  if (!selectedProduct) return <Skeleton active />;
  const handleLink = async (product_id,product_amount,sale_id,point_of_sale_id,customer_type,customer_id) => {
    loadingLink(messageApi);
    try {
      const createLink = await pay_by_link_tokens({
        location_id: getLocationId(),
        expiration_minutes: 0,
        token: user.single_access_token,
        user_id: user.user_id,
        sale_id: sale_id,
      });
      messageApi.destroy();
      setClose();
      // Aquí creas el link para compartir
      const linkToCopy = `https://gym.bigg.fit/mp?location_id=${getLocationId()}&owner_user_id=${owner_user_id}&uuid=${createLink.uuid}&p_id=${product_id}&curr=${getCurrency()}&loc=${getCurrencyLocale()}&p_a=${product_amount}&p_of_s=${point_of_sale_id}&c_t=${customer_type}&c_id=${customer_id}`;
      // Muestra la notificación de éxito si todo fue bien
      openLinkNotification('success', `Compartile este link a ${member_name} para que pueda cargar su método de pago`, 'Link generado con exito',linkToCopy,api,closeMessageApi,messageApi);
    } catch (error) {
      openLinkNotification('error', 'Hubo un problema al copiar el link al portapapeles. Por favor, intentalo nuevamente.',api,closeMessageApi);
    }
  };
  const calculateTotalAmount = () => {
    // console.log('aaa',selectedProduct.selectedPaymentMethods.reduce(
    //   (a, b) => a + parseFloat(b.amount),
    //   ))
    
    return selectedProduct.selectedPaymentMethods.reduce(
      (a, b) => a + parseFloat(b.amount),
      0
    )
  }
  return (
    <div
      onClick={() => {
        setClose();
      }}
      className={`${
        sale ? 'fixed' : 'hidden'
      } inset-0 bg-background bg-opacity-75 backdrop-blur-[1px] flex justify-center items-center z-50`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className='bg-primaryDark rounded-xl  p-7 relative sm:h-fit lg:w-fit lg:min-w-[45%] lg:h-fit lg:top-0 sm:margin-auto sm:left-0 sm:right-0 sm:top-0 sm:bottom-0'
      >
        <div className='flex flex-col  w-full  pb-5'>
          <h1 className='text-3xl  font-MessinaSansSemiBold '>
            {sale.cancelProducts ? t('generate_nc','Generar Nota de credito') : t('cc_pay','Imputar pago') }
          </h1>
          <p className='pt-1 opacity-50'>
            {sale.cancelProducts
              ? t('generate_cc_desc','Se generará una nota de crédito y se cancelará el producto asociado a esta venta')
              : `${t('member_debt','El socio debe')} ${formatPrice(
                  sale.total_due,
                  sale.currency
                )} ${t('from_total_debt','del total de')} ${formatPrice(sale.total)}`}
          </p>
          <div className='flex flex-row items-center gap-3 mt-10'>
            <div
              className={`bg-primaryGrey text-white rounded-sm  py-1 border-[1px] ${
                selectedProduct?.product.id
                  ? 'border-lightGreen'
                  : 'border-transparent'
              }`}
            >
              <p className='py-2 mx-2'>{selectedProduct?.product?.name}</p>
            </div>
            <div className='flex flex-row item-center gap-4'>
              <div className=' bg-secondaryGrey h-12 flex items-center justify-center px-3 rounded-sm  text-black font-MessinaSansSemiBold text-lg'>
                <p>
                  <span className='mr-1'>$</span>
                </p>
                {selectedProduct.countProducts * selectedProduct.price}
              </div>
            </div>
          </div>
          {!sale.cancelProducts && (
            <SelectPaymentMethod
              paymentType={paymentType}
          
              owner_user_id={owner_user_id}
              paymentPlatform={paymentPlatform}
              product_name={selectedProduct.product.name}
              openAddCreditCard = {openAddCreditCard}
              setopenAddCreditCard = {setopenAddCreditCard}
              setSelectedPaymentMethods={(selectedPaymentMethods) => {
                setselectedProduct({
                  ...selectedProduct,
                  selectedPaymentMethods: selectedPaymentMethods,
                });
              }}
              totalAmount={parseFloat(sale.total_due)}
              selectedPaymentMethods={selectedProduct.selectedPaymentMethods}
              selectedProducts={[selectedProduct]}
            />
          )}
           {openAddCreditCard && <div className='pt-8'>
            <h1 className='text-2xl  font-MessinaSansSemiBold  mb-8'>
            {t("sales.add_card_paymentMethod","Agregar nuevo método de pago")}
          </h1>
            <MPCardHolder 
            totalAmount={1}
            paymentPlatform={paymentPlatform}
            email={owner_email}
            modalSale={openAddCreditCard}
            selectedProducts={[selectedProduct]}
            openNotification={openNotification}
            document_types={document_types}
            setClose={(card_id)=>{
              setopenAddCreditCard(false);
setSelectedPaymentMethods(selectedPaymentMethods.map((pm) => {
  if (pm.paymentType == 52|| pm.paymentType == 48) {
    return {
      ...pm,  
      credit_card_id: card_id,
    };
  }
  return pm;
}
));
            }}
            user_id={owner_user_id}
            />
          </div>
            }
        </div>
        {sale.cancelProducts && (
          <div className='pt-6'>
            <h2 className='text-xl font-MessinaSansSemiBold'>
              {t('return.reason', 'Motivo de devolución')}
            </h2>
            <Select
              loading={isLoadingDischarge}
              ref={dischargeRef}
              // loading={isLoadingProducts && isLoadingConsumables}
              showSearch
              placeholder={
                <div className='flex items-center z-10'>
                  <span className='text-white'>
                    {t('select_reason_refund','Seleccioná el motivo de devolución...')}
                  </span>
                </div>
              }
              allowClear
              optionLabelProp='title'
              value={selectedReason}
              // value={selectedProduct.product_id}
              optionFilterProp='children'
              onChange={(value) => {
                dischargeRef.current.blur();
                setselectedReason(value);
              }}
              bordered={false}
              rootClassName=' text-white '
              className={`mt-5 bg-primaryGrey text-white rounded-sm py-2
  
       `}
              style={{
                width: '45%',
              }}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={dischargeReasonsFilter?.map((reason) => ({
                label:
                  reason.name +
                  (reason.description ? ' - ' + reason.description : ''),
                value: reason.id,
                title: reason.name,
              }))}
            />
          </div>
        )}

        <FooterSaleModal
          sendCC={
            !sale.cancelProducts &&
            (selectedProduct.selectedPaymentMethods.reduce(
              (a, b) => a + parseFloat(b.amount),
              0
            ) < sale.total_due ||
              selectedProduct.selectedPaymentMethods.filter(
                (p) => !isNaN(p.amount)
              ).length == 0)
          }
            title={needLinkMp()&&
          <div className='flex-row flex items-center gap-2'><CiLink size={20} className='' />
        <span>Generar link de Pago en Mercado Pago por {formatPrice(calculateTotalAmount())}</span></div>
        }
          bg={needLinkMp()&&'bg-primaryWhite'}
          selectedProducts={[selectedProduct]}
          cancelProducts={sale.cancelProducts}
          totalAmount={calculateTotalAmount()}
          totalSale={sale.total_due}
          isLoading={
            useCreatePayment.isLoading ||
            useCreateCreditNote.isLoading ||
            useUpdateMembership.isLoading
          }
        
          handleCreateSale={handleCreateSale}
          setmodalSale={setClose}
        />
      </div>
      {contextHolderMessage}
    </div>
  );
}
