import React ,{useState}from 'react';
import CardRounded from '../CardRounded';
import DailyOperations from '../Desktop/DailyOperations';

import DebitMember from './DebitMember';
import ListMemberProducts from './ListMemberProducts';
import ListMemberCC from './ListMemberCC';
import { t } from 'i18next';
import MemberCreditCards from './MemberCreditCards';
import { useMemberCreditCards } from '../../api/mp';
import CloneCreditCard from '../helpers/Modals/CloneCreditCard';
export default function ProductsMember({
  products,
  customer_id,
  debits,
  handleOpenDebit,
  setmodalSale,
  isProspect,
  openNotification,
  dischargeReasons,
  setopenCC,
  handleOpenReturn,
  handleOpenCreditCard,
  user_id,
  paymentPlatform,
  member_name,
  api,
  document_types
}) {

  if (!products) return null;
  const [dataCloneCard, setdataCloneCard] = useState(false)
  const { data: credit_cards, isLoading } =
    paymentPlatform?.length > 0
      ? useMemberCreditCards(user_id)
      : { data: [], isLoading: false };

  return (
    <div>
      <ListMemberProducts
        openNotification={openNotification}
        customer_type={isProspect ? 'Prospect' : 'Member'}
        customer_id={customer_id}
        products={products}
        isProspect={isProspect}
      />
      {/* Debits */}

      <DebitMember
        openNotification={openNotification}
        debits={debits}
        handleOpenDebit={handleOpenDebit}
        customer_id={customer_id}
        isProspect={isProspect}
        dischargeReasons={dischargeReasons}
        user_id={user_id}
        paymentPlatform={paymentPlatform}
        api={api}
        member_name={member_name}
      />
      {/* CC */}

      <div className='mb-10'>
        <h3 className='font-MessinaSansSemiBold text-xl pb-4'>
          {t('cc_title', 'Cuenta corriente')}
        </h3>
        <CardRounded>
          <ListMemberCC
            setopenCC={(cc) => setopenCC(cc)}
            openNotification={openNotification}
            customer_id={customer_id}
            customer_type={isProspect ? 'Prospect' : 'Member'}
          />
        </CardRounded>
      </div>

      {/* Cards */}
      {paymentPlatform?.length > 0 && (
        <div className='mb-10'>
          <MemberCreditCards
                  debits={debits}

            user_id={user_id}
            openNotification={openNotification}
            handleOpenCreditCard={(cc) => handleOpenCreditCard(cc)}
  handleCloneCard={(cc) => setdataCloneCard(cc)}
            customer_id={customer_id}
            customer_type={isProspect ? 'Prospect' : 'Member'}
            member_name={member_name}
            credit_cards={credit_cards}
            isLoading={isLoading}
            document_types={document_types}
          />
        </div>
      )}
      {/* Transacciones */}
      <DailyOperations
        paymentPlatform={paymentPlatform}
        handleOpenReturn={handleOpenReturn}
        customer_type={isProspect ? 'Prospect' : 'Member'}
        setmodalSale={setmodalSale}
        customer_id={customer_id}
        openNotification={openNotification}
        credit_cards={credit_cards}
      />
      <CloneCreditCard 
      data={dataCloneCard}
      setClose={()=>setdataCloneCard(false)}
      customer_id={customer_id}
      openNotification={openNotification}
      document_types={document_types}

      />
      <ListMemberProducts
        showInvalid={true}
        openNotification={openNotification}
        customer_type={isProspect ? 'Prospect' : 'Member'}
        customer_id={customer_id}
        products={products}
        isProspect={isProspect}
      />
    </div>
  );
}
