import React, { useState, useMemo } from 'react';
import { Button, Modal, Select, Tag } from 'antd';
import { BsArrowRight } from 'react-icons/bs';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { formatError } from '../../../services/utils/formatError';
import { updateDebit, updateDebitSplit } from '../../../api/debits';
import { useGetDiscounts } from '../../../api/discounts';
import { getLocationId, getLocationRegionConfig } from '../../../services/utils/getLocation';
import { useGlobalContext } from '../../../context/GlobalState';
import { useGetMemberships } from '../../../api/memberships';
import { formatPrice } from '../../../services/utils/formatPrice';
import { useGetLocationPayment_methods, useGetPayment_methods } from '../../../api/payments';
const UpdateDebitAttr = ({
  openNotification,
  membership,
  setClose,
  data,
  customer_id,
}) => {
  if (!data) return <></>;

  const [newDiscount, setnewDiscount] = useState(false);
  const [newPaymentMethod, setnewPaymentMethod] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState(false);
  const queryClient = useQueryClient();
  const { state } = useGlobalContext();
  const location_id = getLocationId();

  const { data: discounts, isLoading: isLoadingDiscounts } = data.edit !== 'edit_discount'
    ? { data: [], isLoading: false }
    : useGetDiscounts();
    const { data: payment_methods, isLoading: isLoadingPayments } = data.edit !== 'modify_payment_method'
    ? { data: [], isLoading: false }
    : useGetLocationPayment_methods();
    
  const { data: memberships, isLoading: isLoadingProducts } = membership
    ? useGetMemberships(data.location_id)
    : { data: [], isLoading: false };

    const categorizePayments = useMemo(() => {
      if (!payment_methods) return [];
      return payment_methods
        .filter((payment_m) => payment_m.location_id == location_id && payment_m.recurrent)
        .map((paymentType) => ({
          label: paymentType.name,
          value: paymentType.id,
          title: paymentType.name,
          disabled: paymentType.id == data.payment_method_id
        }));
    }, [payment_methods]);
  const categorizeDiscounts = useMemo(() => {
    if (!discounts) return [];
    const AvailableRegionConfig = getLocationRegionConfig(state.locations).map(
      (config) => config.id
    );
    let filterByRegionConfig = discounts.filter(
      (d) =>
        AvailableRegionConfig.includes(d.region_config_type_id) &&
        d.active &&
        d.recurrent
    ); //remove cdp and gympass product
    return filterByRegionConfig.map((discount) => ({
      key: discount.id,
      label: discount.name + '-' + parseFloat(discount.amount) + '%',
      value: discount.id,
      className: discount.id == newDiscount.id ? 'bg-lightGreen' : '',
      disabled: discount.id == 124, // discount bonificar proporcional
    }));
  }, [discounts]);
  const categorizeMemberships = useMemo(() => {
    if (!memberships) return [];
    const AvailableRegionConfig = getLocationRegionConfig(state.locations).map(
      (config) => config.id
    );
    let filterByRegionConfig = memberships.filter(
      (product) =>
        product?.product.recurrent &&
        AvailableRegionConfig.includes(
          product.product.available_region_config_type_id
        )
    );
    return filterByRegionConfig.map((product) => ({
      value: product.id,
      label: product.name + '-' + formatPrice(product.price),
      className: `${
        selectedProduct?.id == product.id ? 'bg-lightGreen' : ''
      }`,
      title: product.name,
    }));
  }, [memberships]);
  const useUpdateDebit = useMutation({
    mutationFn: (debit) => updateDebit(debit.id, debit.data),
    onSuccess: (data) => {
      openNotification('success', 'Debito actualizado correctamente');
      queryClient.invalidateQueries({
        queryKey: ['profile', customer_id],
      });
      setClose();
    },
    onError: (error) => {
      formatError(error, openNotification);
    },
  });
  const UseupdateDebitSplit= useMutation({
    mutationFn: (data) => updateDebitSplit(data.id,data.data),
    onSuccess: async (data) => {
      openNotification('success', 'Debito actualizado correctamente');
      queryClient.invalidateQueries({
        queryKey: ['profile', customer_id],
      });
      setClose();
    },
    onError: (error, variables) => {
      formatError(error, openNotification);
    },
  });
  return (
    <div
      onClick={() => {
        setClose();
      }}
      className={`${
        data ? 'fixed' : 'hidden'
      } inset-0 bg-background bg-opacity-75 backdrop-blur-[1px] flex justify-center items-center z-50`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className='bg-primaryDark rounded-xl  p-7 relative sm:h-fit lg:w-fit lg:min-w-[35%] lg:h-fit lg:top-0 sm:margin-auto sm:left-0 sm:right-0 sm:top-0 sm:bottom-0'
      >
        <h2 className='text-3xl  font-MessinaSansSemiBold'>
          Modificar {membership ? 'Producto' : data.edit !== 'modify_payment_method'? 'Descuento' : 'Método de pago'}
        </h2>
        <div className='flex flex-row items-center justify-between my-5 w-full '>
          <div className='flex-1 '>
            <p className='text-secondaryGrey opacity-80 max-w-xs break-words'>
              {membership ? 'Producto' :  data.edit !== 'modify_payment_method' ?'Descuento' :
              'Método de pago' } actual
            </p>
            <div className='py-2 my-2 px-2 opacity-80 bg-primaryGrey flex items-center justify-center text-secondaryGrey'>
              {membership ? data.membership.product.name : 
              data.edit !== 'modify_payment_method' ?
              data?.discount?.name : data.payment_method_name}
            </div>
          </div>
          <BsArrowRight
            className='mx-4 self-center mt-4'
            color={'#ADFF19'}
            size={22}
          />
          <div className='flex-1 '>
            <p className='text-secondaryGrey opacity-80 max-w-xs break-all'>
              Seleccionar nuevo {membership ? 'Producto' : data.edit !== 'modify_payment_method' ? 'descuento' :'método de pago'}
            </p>
            <Select
              loading={membership ? isLoadingProducts :
                data.edit !== 'modify_payment_method' ? 

                isLoadingDiscounts :
                isLoadingPayments
              }
              allowClear
              options={membership ? categorizeMemberships :
                data.edit !== 'modify_payment_method' ? 
                categorizeDiscounts : categorizePayments}
              value={membership ? selectedProduct : 
                data.edit !== 'modify_payment_method' ?
                newDiscount:
                newPaymentMethod
              }
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              optionFilterProp='children'
              onChange={(value, option) => {
                membership
                  ? setSelectedProduct(option)
                  : 
                  data.edit !== 'modify_payment_method' ?
                  setnewDiscount(option) : setnewPaymentMethod(option);
              }}
              variant='borderless'
              placeholder='Seleccionar descuento'
              style={{
                minWidth: '60%',
              }}
              className='py-2 my-2 px-2 opacity-80 bg-primaryGrey flex items-center justify-center text-secondaryGrey outline-none appearance-none'
            />
          </div>
        </div>

        <div className='flex flex-items self-start gap-5  mt-4'></div>
        <div className='flex items-end self-end  justify-end gap-3 mt-4'>
          <Button danger onClick={() => setClose()}>
            Cancelar
          </Button>
          <Button
            loading={useUpdateDebit.isLoading
            || UseupdateDebitSplit.isLoading
            }
            type='primary'
            onClick={async () =>
              data.edit === 'modify_payment_method'
                ? await UseupdateDebitSplit.mutateAsync({
                  id: data.id,
                  data: { payment_method_id: newPaymentMethod?.value },
                })
                :
              await useUpdateDebit.mutateAsync({
                id: data.id,
                data: membership
                  ? { membership_id: selectedProduct?.value }
                  : {
                      discount_id: newDiscount?.value,
                    },
              })
            }
          >
            Aceptar
          </Button>
        </div>
      </div>
    </div>
  );
};
export default UpdateDebitAttr;
