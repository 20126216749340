import React, { useRef, useState } from 'react';
import { Select } from 'antd';
import { BsChevronDown } from 'react-icons/bs';
import { search_all } from '../../api/search';

export default function SearchReferrer({valueSelect,handleChange}) {
  const [data, setData] = useState([]);
  const refSelect = useRef();
  const fetch = async (value, callback) => {
    const result = await search_all(value);
    const data = result.user_results.map((item) => ({
      value: item.user_id,
      text: item.first_name + ' ' + item.last_name,
    }));
    callback(data);
  };
  const handleSearch = (newValue) => {
    fetch(newValue, setData);
  };

  return (
    <Select
    ref={refSelect}
      value={valueSelect}
      bordered={false}
      placeholder={<span className=' opacity-30'>Buscar referido...</span>}
      suffixIcon={<BsChevronDown color='#ADFF19' />}
      className='w-full text-white outline-none  border-none  cursor-pointer  rounded-md font-MessinaSansSemiBold py-1'
      style={{
        fontSize: 44,
        cursor: 'pointer',  
      }}
      notFoundContent={ () => <div className='text-white  z-50 pb-50 pt-20 bg-black'>No data found</div>}
      showSearch
      defaultActiveFirstOption={false}
      filterOption={false}
      onChange={(v)=>{
        refSelect.current.blur();
        handleChange(v);
      }}
      onSearch={handleSearch}
      options={(data || []).map((d) => ({
        value: d.value,
        label: d.text,
      }))}
    />
  );
}
