import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import TableCustom from '../components/TableCustom';
import {   USE_FORM_COACH, USE_FORM_COACH_LOCATION } from '../services/Constants/FORMS';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'antd';
import { USECoachColumns } from '../services/Constants/UsersColumns';
import { getDocumentTypes, getUsers } from '../api/users';
import { getLocationId, getMaster_account_id } from '../services/utils/getLocation';
import {
  createCoach,
  createCoachLocation,
  deleteCoach,
  deleteCoachLocation,
  getCoach,
  updateCoach,
  useGetCoaches,
} from '../api/coaches';
import { openNotificationFields } from '../services/utils/openNotificationFields';
import {canUpdateCoaches, isAdmin } from '../services/can_user';
import { getAvailableLocations, getLocations } from '../api/locations';
import { t } from 'i18next';
const TITLE = 'Coaches';
const TITLE_SINGULAR = 'Coach';
export default function Coaches() {
  const columns = USECoachColumns();
  const location_id = getLocationId()
  const FORM_COACH = USE_FORM_COACH()
  const FORM_COACH_LOCATION = USE_FORM_COACH_LOCATION()
  const { data, isLoading } = useGetCoaches(location_id);
  const [filterTable, setfilterTable] = useState(isLoading ? [] : data);
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    setfilterTable(isLoading ? [] : data);
  }, [data]);

  const openNotification = (type, description) => {
    api[type]({
      message:
        type == 'success'
          ?  t('coach.update_success',`Coach actualizado correctamente`)
          :  t('coach.update_error',`Ocurrió un error al actualizar el coach`),
...openNotificationFields(type,description)
    });
  };

  const [FORM, setFORM] = useState(FORM_COACH);
  const [FORMCREATE, setFORMCREATE] = useState(FORM_COACH_LOCATION);

  const [searchInput, setsearchInput] = useState('');
  const [openDrawer, setopenDrawer] = useState({
    visible: false,
    record: null,
  });
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({});
  const onSubmit = async (data) => {
    if (!openDrawer.record) {
      const location_id = getLocationId()
      const coach_location = {
        location_id: location_id,
        coach_id: data.coach_id,
        active:true
      }
     
      const result = await createCoachLocation(coach_location)
      openNotification('success');
      queryClient.invalidateQueries({
        queryKey:['coaches',location_id]
      });

      return setopenDrawer({ visible: false, record: null });
    }
    let user = {
      user_id: openDrawer.record.user_id,
      id: openDrawer.record.id,
      observation: data.observation,
      active:data.active,
      user_attributes: {
        ...data,
        id: openDrawer.record.user_id,
        user_id: openDrawer.record.user_id,
        master_account_id: getMaster_account_id(),
        active:true
      },
    };
    const locationToRemove = openDrawer.record.locations.filter((item) => !data.locations.includes(item.id));
    const locationToAdd = data.locations.filter((item) => !openDrawer.record.locations.map((item) => item.id).includes(item));
    // const coach_location = {
    //   location_id: location_id,
    //   coach_id: data.id,
    // }
    if(locationToRemove.length > 0){
      locationToRemove.forEach(async (item) => {
      await useDeleteCoachLocation.mutateAsync(item.coach_location_id);
      });
    }
    if(locationToAdd.length > 0){
      locationToAdd.forEach(async (item) => {
        const coach_location = {
          location_id: item,
          coach_id: openDrawer.record.id,
        }
        await useCreateCoachLocation.mutateAsync(coach_location);
      });
    }
    await useUpdateCoach.mutateAsync(user);
    setopenDrawer({ visible: false, record: null });
    setFORM(FORM_COACH);
  };
  const handleOpenDrawer = async (visible, record) => {
    reset();
    if (visible) {
      try {
        let coach;
        if (record) {
          coach = await getCoach(record.id);
          FORM.forEach((item) => {
            if(item.name == 'locations'){
              setValue(
                'locations',
                coach.locations.map((item) => item.id)
              );
            } else {
              setValue(item.name, coach[item.name]);
            }
          });
        }

        const document_types = await getDocumentTypes();
        const locations = await getAvailableLocations();
        const locations_all = await getLocations();
        if(!record){
          const coaches = await getUsers();
          setFORM(
            FORMCREATE.map((item) => {
              if (item.name === 'coach_id') {
                return {
                  ...item,
                  options: coaches.filter((c)=>c.coach_id &&
                    c.roles.some((r)=>r.id ==3 && !data.map((m)=>m.id).includes(
                      c.coach_id
                    )
                    )
                  ).map((item) => ({
                    label: item.first_name +' '+ item.last_name,
                    value: item.coach_id,
                  })),
                };
              }
              return item;
            }
          )
          );
          return setopenDrawer({ visible: visible, record: record });
        }
        console.log('FORM',FORM);
        setFORM(
          FORM.map((item) => {
            if (item.name === 'document_type_id') {
              return {
                ...item,
                options: document_types.map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
              };
            }
            if (item.name === 'locations') {
              return {
                ...item,
                options: locations_all.map((item) => ({
                  label: item.name,
                  value: item.id,
                  // removeIcon
                  disabled: isAdmin ? false : !locations.locations.map((item) => item.id).includes(item.id) ? true : false,
                }))
                // .filter((l)=> locations.locations.map((item) => item.id).includes(l.value) ? true : false)
              };
            }
            return item;
          })
        );
        setopenDrawer({ visible: visible, record: record });
      } catch (error) {
        console.error('Error opening drawer:', error);
      }
    } else {
      setopenDrawer({ visible: visible, record: record });
      setFORM(FORM_COACH);
    }
  };
  const useUpdateCoach = useMutation({
    mutationFn: (coach) => updateCoach(coach.id, coach),
    onSuccess: () => {
      openNotification('success');
      queryClient.invalidateQueries({
        queryKey:['coaches',location_id]
      });
    },
    onError: (error) => {
      let errorParse = JSON.parse(error.message);
      openNotification(
        'error',
        Object.keys(errorParse).map((key) => `${key} : ${errorParse[key]}`)
      );
    },
  });
  const useCreateCoach = useMutation({
    mutationFn: (coach) => createCoach(coach),
    onSuccess: () => {
      openNotification('success');
      queryClient.invalidateQueries({
        queryKey:['coaches',location_id]
      });
    },
    onError: (error) => {
      let errorParse = JSON.parse(error.message);
      openNotification(
        'error',
        Object.keys(errorParse).map((key) => `${key} : ${errorParse[key]}`)
      );
    },
  });
  const useDeleteCoach = useMutation({
    mutationFn: (user) => deleteCoach(user.user_id,user),
    onSuccess: () => {
      openNotification('success');
      queryClient.invalidateQueries({
        queryKey:['coaches',location_id]
      });
    },
    onError: (error) => {
      let errorParse = JSON.parse(error.message);
      openNotification(
        'error',
        Object.keys(errorParse).map((key) => `${key} : ${errorParse[key]}`)
      );
    },
  });
  const useCreateCoachLocation = useMutation({
    mutationFn: (coach) => createCoachLocation(coach),
    onSuccess: () => {
      // openNotification('success');
      // queryClient.invalidateQueries({
      //   queryKey:['coaches',location_id]
      // });
    },
    onError: (error) => {
      let errorParse = JSON.parse(error.message);
      openNotification(
        'error',
        Object.keys(errorParse).map((key) => `${key} : ${errorParse[key]}`)
      );
    },
  });
  const useDeleteCoachLocation = useMutation({
    mutationFn: (id) => deleteCoachLocation(id),
    onSuccess: () => {
      // openNotification('success');
      // queryClient.invalidateQueries({
      //   queryKey:['coaches',location_id]
      // });
    },
    onError: (error) => {
      let errorParse = JSON.parse(error.message);
      openNotification(
        'error',
        Object.keys(errorParse).map((key) => `${key} : ${errorParse[key]}`)
      );
    },
  });
  return (
    <>
      <TableCustom
        filterTable={filterTable}
        data={data}
        canUpdate={canUpdateCoaches()}
        canCreate={canUpdateCoaches()}
        openDrawer={openDrawer}
        setfilterTable={setfilterTable}
        handleOpenDrawer={handleOpenDrawer}
        title={TITLE}
        title_singular={TITLE_SINGULAR}
        searchInput={searchInput}
        onSubmit={onSubmit}
        control={control}
        FORM={FORM}
        originalColumns={columns}
        register={register}
        handleSubmit={handleSubmit}
        setsearchInput={setsearchInput}
        isLoading={isLoading}
        mutateLoading={useCreateCoach.isLoading || useUpdateCoach.isLoading}
      />
      {contextHolder}
    </>
  );
}
