import { customerIoIdentifyWithData, customerIoTrack } from './CustomerIoUtils';

export const create_prospect = (data) => {
  customerIoIdentifyWithData(data);
};

export const track_sale = (data) => {
  customerIoTrack('crm_create_sale', data);
};

export const track_reserve_class = (data) => {
  customerIoTrack('crm_reserve_class', data);
};

export const track_deleted_reserve_class = (data) => {
  customerIoTrack('crm_deleted_reserve_class', data);
};
export const track_attendance_reserve_class = (data) => {
  customerIoTrack('crm_attendance_reserve_class', data);
};
export const track_create_crm = (data) => {
  customerIoTrack('crm_create_crm', data);
};
export const track_modify_password = (data) => {
  customerIoTrack('crm_modify_password', data);
};
export const track_modify_cdpCoach = (data) => {
  customerIoTrack('crm_modify_leadCoach', data);
};
export const track_prospect_refered = (data) => {
  customerIoTrack('prospect_refered', data);
};
export const track_modify_discharge_reason_id = (data) => {
  customerIoTrack('crm_modify_discharge_reason', data);
};

export const track_modify_emailUser = (data) => {
  customerIoTrack('crm_modify_emailUser', data);
};
export const track_modify_locationUser = (data) => {
  customerIoTrack('crm_modify_locationUser', data);
};
