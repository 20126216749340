import React, { useMemo, useState } from 'react';
import {
  CardPayment,
  initMercadoPago,
  createCardToken,
} from '@mercadopago/sdk-react';
import { createMemberCreditCard } from '../../api/mp';
import { useMutation } from '@tanstack/react-query';
import { formatError } from '../../services/utils/formatError';
import { Checkbox } from 'antd';
import { updateUser } from '../../api/users';

export default function MPCardHolder({
  modalSale,
  email,
  selectedProducts,
  paymentPlatform,
  openNotification,
  setClose,
  user_id,
  background,
  document_types,
  client_link,
  address_required,
  address,
  setaddress
}) {
 
  if (!modalSale) return null;
  if (selectedProducts.length === 0) return null;
  if (!paymentPlatform) return <></>;
  const [checked_third, setchecked_third] = useState(false);
  const [loadindBrick, setloadindBrick] = useState(true);
  const [error, setError] = useState(false); // Para manejar el estado de error
  const [touched, setTouched] = useState(false); // Para rastrear si el campo fue tocado
  initMercadoPago(paymentPlatform[0].public_key, {
    locale: 'es-AR',
  });
  const useUpdateUser = useMutation({
    mutationFn: (user) => updateUser(user.id, user),
    onSuccess: (data) => {},
    onError: (error) => formatError(error, openNotification),
  });
  const useCreateCreditCard = useMutation({
    mutationFn: (card) => createMemberCreditCard(card),
    onSuccess: async (data, variables) => {
      if (!checked_third && !client_link) {
        if (document_types && document_types?.length > 0) {
          let updateIdentification = {
            document_number: variables.payer.identification.number,
          };
          if (
            document_types.find(
              (d) => d.name == variables.payer.identification.type
            )?.id
          ) {
            updateIdentification = {
              ...updateIdentification,
              document_type_id: document_types.find(
                (d) => d.name == variables.payer.identification.type
              )?.id,
            };
          }
          if (updateIdentification.document_type_id) {
            let updateMember = {
              id: user_id,
              user_id: user_id,
            };
            updateMember.user = {
              id: user_id,
              email: variables.payer.email,
              document_number: updateIdentification.document_number,
              document_type_id: updateIdentification.document_type_id,
            };
            await useUpdateUser.mutateAsync(updateMember);
          }
        }
      }
      setClose('success', data);
    },
    onError: (error, variables) => {
      let errorParse = JSON.parse(error.message);
      // openNotification(
      //   'error',
      //   Object.keys(errorParse).map((key) => `${key} : ${errorParse[key]}`)
      // );
      setClose('error');
    },
  });
  const cardPaymentComponent = useMemo(
    () => (
      <CardPayment
        customization={{
          paymentMethods: {
            maxInstallments: 1,
          },
          visual: {
            hideFormTitle: client_link ? false : true,
            texts: {
              formSubmit: 'Guardar Metodo de pago',
            },
            style: {
              customVariables: {
                inputBackgroundColor: '#444444',
                formBackgroundColor: background ? background : '#222222',
                textPrimaryColor: '#FFFFFF',
                outlinePrimaryColor: 'transparent',
                fontSizeSmall: '16px',
                outlineSecondaryColor: '#CEF5CE',
                fontSizeMedium: '17px',
                inputVerticalPadding: '12px',
                formPadding: '0px',
                inputBorderWidth: '0px',
              },
            },
          },
        }}
        onReady={() => setloadindBrick(false)}
        initialization={{
          amount: 1000,
          payer: {
            email: client_link ? false : email,
          },
        }}
        onError={(error) => {
          console.error('Error', error);

        }}
        onSubmit={async (cardFormData, additionalData) => {
          let card = {
            card_brand: cardFormData.payment_method_id,
            external_payment_token: cardFormData.token,
            user_id: user_id,
            payment_platform_id: paymentPlatform[0].id,
            last_four: additionalData.lastFourDigits,
            payment_method_id: paymentPlatform[0].payment_method_id,
            external_email: cardFormData.payer.email,
            preferred: false,
            payer: cardFormData.payer,
          };
          await useCreateCreditCard.mutateAsync(card);
        }}
      />
    ),
    []
  );

  return (
    <div className={`relative  ${!client_link  && 'mt-16 '} ${address_required && 'mt-36'}`}>
      {cardPaymentComponent}
      {!loadindBrick && !client_link && (
        <div className='flex flex-row gap-2 absolute   top-[-35px]'>
          <Checkbox
            checked={checked_third}
            onChange={(e) => setchecked_third(e.target.checked)}
          />
          <p>La tarjeta es de un tercero</p>
        </div>
      )}
  {!loadindBrick && address_required && (
  <div className='flex flex-col gap-2 absolute top-[-120px]'>
    <p className='font-MessinaSansSemiBold text-lg'>Dirección de facturación</p>
    <input
      type='text'
      value={address}
      onFocus={() => setTouched(true)} // Marcar como tocado al hacer focus
      onBlur={() => setError(!address)} // Validar si el campo está vacío al salir
      onChange={(e) => {
        setaddress(e.target.value);
        setError(false); // Quitar el error al escribir
      }}
      placeholder='Dirección de facturación'
      className={`bg-primaryGrey text-sm font-MessinaSansWebRegular min-w-[300px] px-2 text-white py-3 rounded-md outline-none appearance-none ${
        error ? 'border-red-500 border' : ''
      }`}
    />
    {error && (
      <span className='text-red-500 text-md font-MessinaSansSemiBold mt-[-5px]'>
        Este campo es requerido.
      </span>
    )}
  </div>
)}
    </div>
  );
}
