import dayjs from 'dayjs';
export const compareByCreatedAt = (a, b) => {
  const dateA = dayjs(a.created_at);
  const dateB = dayjs(b.created_at);

  if (dateA.isBefore(dateB)) {
    return 1;
  }
  if (dateA.isAfter(dateB)) {
    return -1;
  }
  return 0;
};
export const compareByCreatedAtOrSentAt = (a, b) => {
  // Verificamos si el action_type_id es 2, 3 o 5
  const actionTypes = [2, 3, 4, 5, 6];

  if (
    actionTypes.includes(a.action_type_id) &&
    actionTypes.includes(b.action_type_id)
  ) {
    // Si ambos tienen un action_type_id de 2, 3, o 5, comparamos por sent_at
    const dateA = dayjs(a.sent_at_original);
    const dateB = dayjs(b.sent_at_original);

    if (dateA.isBefore(dateB)) {
      return 1;
    }
    if (dateA.isAfter(dateB)) {
      return -1;
    }
    return 0;
  }

  // Si no tienen esos action_type_id, comparamos por created_at
  const dateA = dayjs(a.created_at);
  const dateB = dayjs(b.created_at);

  if (dateA.isBefore(dateB)) {
    return 1;
  }
  if (dateA.isAfter(dateB)) {
    return -1;
  }
  return 0;
};
export const compareByDate = (a, b, field) => {
  const dateA = dayjs(a[field]);
  const dateB = dayjs(b[field]);

  if (dateA.isBefore(dateB)) {
    return 1;
  }
  if (dateA.isAfter(dateB)) {
    return -1;
  }
  return 0;
};
